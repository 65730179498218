import { useState } from 'react'

function Typebox(props) {
  const [name, setName] = useState('')
  const updateName = (newName, pressedKey) => {
    setName(newName)
    if (pressedKey === 'Enter') {
      props.onBooking(name)
    }
    if (pressedKey === 'Escape') {
      props.onCancel()
      return
    }
  }

  const displayDate = (dateString, locale = 'en-US') => {
    var date = new Date(dateString.replace(/-/g, '/'))
    return (
      dateString +
      ' (' +
      date.toLocaleDateString(locale, { weekday: 'short' }) +
      ')'
    )
  }

  return (
    <div className="Typebox z-30 fixed inset-0 flex justify-center items-center flex-col bg-white">
      <div className="text-2xl text-center mb-6">
        Booking at{' '}
        <span className="font-bold">
          {props.timeOfBooking.replace(/(..)/g, '$1:').slice(0, -1)}
        </span>
        &nbsp;on {displayDate(props.dayOfBooking)}
      </div>
      {props.alreadyName && (
        <div className="text-lg text-center mb-6">
          <span className="font-bold">{props.alreadyName}</span> has already
          booked that time.
          <br />
        </div>
      )}
      <input
        autoFocus
        onKeyUp={(e) => updateName(e.target.value, e.key)}
        className="w-2/3 md:w-1/3 px-3 py-2 text-lg border-2 text-center"
        type="text"
        placeholder="Enter your name..."
      />
      <button
        href="#"
        onClick={() => props.onBooking(name)}
        className="text-center cursor-pointer w-2/3 md:w-1/3 bg-dark text-white p-2 text-xl mt-4"
      >
        Confirm
      </button>
      {props.alreadyName && (
        <button
          onClick={() => props.onDelete()}
          className="bg-red text-center cursor-pointer w-2/3 md:w-1/3 bg-light text-black p-2 text-xl mt-4 shadow"
        >
          Delete
        </button>
      )}
      <button
        onClick={() => props.onCancel()}
        className="text-center cursor-pointer w-2/3 md:w-1/3 bg-light text-black p-2 text-xl mt-4 shadow"
      >
        Cancel
      </button>
    </div>
  )
}

export default Typebox
