import { Fragment, useState } from 'react'
import BookingWidgetColumn from '../booking-widget-column/BookingWidgetColumn'
import Typebox from '../typebox/Typebox'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

function BookingWidget(props) {
  const [isBooking, setIsBooking] = useState(0)
  const [timeOfBooking, setTimeOfBooking] = useState(0)
  const [dayOfBooking, setDayOfBooking] = useState(0)
  const [alreadyName, setAlreadyName] = useState(0)

  const bookingIntent = (day, time, currentName, displayDate) => {
    setDayOfBooking(day)
    setTimeOfBooking(time)
    setIsBooking(1)
    setAlreadyName(currentName)
  }

  const cancelBox = () => {
    setIsBooking(0)
    setAlreadyName(0)
  }

  const deleteBooking = () => {
    setIsBooking(0)
    setAlreadyName(0)
    props.onBooking(dayOfBooking, timeOfBooking, '')
  }

  const book = (name) => {
    setIsBooking(0)
    setAlreadyName(0)
    props.onBooking(dayOfBooking, timeOfBooking, name)
  }

  const d = new Date()
  const day0 =
    d.getUTCFullYear() + '-' + (d.getUTCMonth() + 1) + '-' + d.getUTCDate()

  const d1 = new Date()
  d1.setDate(d1.getUTCDate() + 1)
  const day1 =
    d1.getUTCFullYear() + '-' + (d1.getUTCMonth() + 1) + '-' + d1.getUTCDate()

  const d2 = new Date()
  d2.setDate(d2.getUTCDate() + 2)
  const day2 =
    d2.getUTCFullYear() + '-' + (d2.getUTCMonth() + 1) + '-' + d2.getUTCDate()

  const d3 = new Date()
  d3.setDate(d3.getUTCDate() + 3)
  const day3 =
    d3.getUTCFullYear() + '-' + (d3.getUTCMonth() + 1) + '-' + d3.getUTCDate()

  const d4 = new Date()
  d4.setDate(d4.getUTCDate() + 4)
  const day4 =
    d4.getUTCFullYear() + '-' + (d4.getUTCMonth() + 1) + '-' + d4.getUTCDate()

  const d5 = new Date()
  d5.setDate(d5.getUTCDate() + 5)
  const day5 =
    d5.getUTCFullYear() + '-' + (d5.getUTCMonth() + 1) + '-' + d5.getUTCDate()

  const d6 = new Date()
  d6.setDate(d6.getUTCDate() + 6)
  const day6 =
    d6.getUTCFullYear() + '-' + (d6.getUTCMonth() + 1) + '-' + d6.getUTCDate()

  const d7 = new Date()
  d7.setDate(d7.getUTCDate() + 7)
  const day7 =
    d7.getUTCFullYear() + '-' + (d7.getUTCMonth() + 1) + '-' + d7.getUTCDate()

  const d8 = new Date()
  d8.setDate(d8.getUTCDate() + 8)
  const day8 =
    d8.getUTCFullYear() + '-' + (d8.getUTCMonth() + 1) + '-' + d8.getUTCDate()

  const d9 = new Date()
  d9.setDate(d9.getUTCDate() + 9)
  const day9 =
    d9.getUTCFullYear() + '-' + (d9.getUTCMonth() + 1) + '-' + d9.getUTCDate()

  const d10 = new Date()
  d10.setDate(d10.getUTCDate() + 10)
  const day10 =
    d10.getUTCFullYear() +
    '-' +
    (d10.getUTCMonth() + 1) +
    '-' +
    d10.getUTCDate()

  const d11 = new Date()
  d11.setDate(d11.getUTCDate() + 11)
  const day11 =
    d11.getUTCFullYear() +
    '-' +
    (d11.getUTCMonth() + 1) +
    '-' +
    d11.getUTCDate()

  const d12 = new Date()
  d12.setDate(d12.getUTCDate() + 12)
  const day12 =
    d12.getUTCFullYear() +
    '-' +
    (d12.getUTCMonth() + 1) +
    '-' +
    d12.getUTCDate()

  const d13 = new Date()
  d13.setDate(d13.getUTCDate() + 13)
  const day13 =
    d13.getUTCFullYear() +
    '-' +
    (d13.getUTCMonth() + 1) +
    '-' +
    d13.getUTCDate()

  const displayDate = (dateString, locale = 'en-US') => {
    var date = new Date(dateString.replace(/-/g, '/'))
    return (
      dateString +
      ' (' +
      date.toLocaleDateString(locale, { weekday: 'short' }) +
      ')'
    )
  }

  return (
    <Fragment>
      <div className="BookingContainer sm:rounded sm:px-10">
        <div className="BookingWidget shadow-lg relative mx-auto">
          <div className="Times absolute top-0 left-0">
            <div className="time sharp">07:00</div>
            <div className="time half">07:30</div>
            <div className="time sharp">08:00</div>
            <div className="time half">08:30</div>
            <div className="time sharp">09:00</div>
            <div className="time half">09:30</div>
            <div className="time sharp">10:00</div>
            <div className="time half">10:30</div>
            <div className="time sharp">11:00</div>
            <div className="time half">11:30</div>
            <div className="time sharp">12:00</div>
            <div className="time half">12:30</div>
            <div className="time sharp">13:00</div>
            <div className="time half">13:30</div>
            <div className="time sharp">14:00</div>
            <div className="time half">14:30</div>
            <div className="time sharp">15:00</div>
            <div className="time half">15:30</div>
            <div className="time sharp">16:00</div>
            <div className="time half">16:30</div>
            <div className="time sharp">17:00</div>
            <div className="time half">17:30</div>
            <div className="time sharp">18:00</div>
            <div className="time half">18:30</div>
            <div className="time sharp">19:00</div>
            <div className="time half">19:30</div>
            <div className="time sharp">20:00</div>
            <div className="time half">20:30</div>
            <div className="time sharp">21:00</div>
            <div className="time half">21:30</div>
            <div className="time sharp">22:00</div>
            <div className="time half">22:30</div>
            <div className="time sharp">23:00</div>
            <div className="time half">23:30</div>
          </div>
          <Swiper
            className="BookingSheet"
            threshold={6}
            breakpoints={{
              0: { slidesPerView: 1 },
              400: { slidesPerView: 2 },
              550: { slidesPerView: 3 },
              800: { slidesPerView: 4 },
              1000: { slidesPerView: 6 },
              1300: { slidesPerView: 7 },
            }}
          >
            <SwiperSlide className="relative z-20">
              <div className="absolute top-0 left-0 right-0 text-center">
                {displayDate(day0)}
              </div>
              <BookingWidgetColumn
                currentRoom={props.currentRoom}
                bookings={props.day0Bookings}
                day={day0}
                onBookingIntent={bookingIntent}
              />
            </SwiperSlide>
            <SwiperSlide>
              <div className="absolute top-0 left-0 right-0 text-center">
                {displayDate(day1)}
              </div>
              <BookingWidgetColumn
                currentRoom={props.currentRoom}
                bookings={props.day1Bookings}
                day={day1}
                onBookingIntent={bookingIntent}
              />
            </SwiperSlide>
            <SwiperSlide>
              <div className="absolute top-0 left-0 right-0 text-center">
                {displayDate(day2)}
              </div>
              <BookingWidgetColumn
                currentRoom={props.currentRoom}
                bookings={props.day2Bookings}
                day={day2}
                onBookingIntent={bookingIntent}
              />
            </SwiperSlide>
            <SwiperSlide>
              <div className="absolute top-0 left-0 right-0 text-center">
                {displayDate(day3)}
              </div>
              <BookingWidgetColumn
                currentRoom={props.currentRoom}
                bookings={props.day3Bookings}
                day={day3}
                onBookingIntent={bookingIntent}
              />
            </SwiperSlide>
            <SwiperSlide>
              <div className="absolute top-0 left-0 right-0 text-center">
                {displayDate(day4)}
              </div>
              <BookingWidgetColumn
                currentRoom={props.currentRoom}
                bookings={props.day4Bookings}
                day={day4}
                onBookingIntent={bookingIntent}
              />
            </SwiperSlide>
            <SwiperSlide>
              <div className="absolute top-0 left-0 right-0 text-center">
                {displayDate(day5)}
              </div>
              <BookingWidgetColumn
                currentRoom={props.currentRoom}
                bookings={props.day5Bookings}
                day={day5}
                onBookingIntent={bookingIntent}
              />
            </SwiperSlide>
            <SwiperSlide>
              <div className="absolute top-0 left-0 right-0 text-center">
                {displayDate(day6)}
              </div>
              <BookingWidgetColumn
                currentRoom={props.currentRoom}
                bookings={props.day6Bookings}
                day={day6}
                onBookingIntent={bookingIntent}
              />
            </SwiperSlide>
            <SwiperSlide>
              <div className="absolute top-0 left-0 right-0 text-center">
                {displayDate(day7)}
              </div>
              <BookingWidgetColumn
                currentRoom={props.currentRoom}
                bookings={props.day7Bookings}
                day={day7}
                onBookingIntent={bookingIntent}
              />
            </SwiperSlide>
            <SwiperSlide>
              <div className="absolute top-0 left-0 right-0 text-center">
                {displayDate(day8)}
              </div>
              <BookingWidgetColumn
                currentRoom={props.currentRoom}
                bookings={props.day8Bookings}
                day={day8}
                onBookingIntent={bookingIntent}
              />
            </SwiperSlide>
            <SwiperSlide>
              <div className="absolute top-0 left-0 right-0 text-center">
                {displayDate(day9)}
              </div>
              <BookingWidgetColumn
                currentRoom={props.currentRoom}
                bookings={props.day9Bookings}
                day={day9}
                onBookingIntent={bookingIntent}
              />
            </SwiperSlide>
            <SwiperSlide>
              <div className="absolute top-0 left-0 right-0 text-center">
                {displayDate(day10)}
              </div>
              <BookingWidgetColumn
                currentRoom={props.currentRoom}
                bookings={props.day10Bookings}
                day={day10}
                onBookingIntent={bookingIntent}
              />
            </SwiperSlide>
            <SwiperSlide>
              <div className="absolute top-0 left-0 right-0 text-center">
                {displayDate(day11)}
              </div>
              <BookingWidgetColumn
                currentRoom={props.currentRoom}
                bookings={props.day11Bookings}
                day={day11}
                onBookingIntent={bookingIntent}
              />
            </SwiperSlide>
            <SwiperSlide>
              <div className="absolute top-0 left-0 right-0 text-center">
                {displayDate(day12)}
              </div>
              <BookingWidgetColumn
                currentRoom={props.currentRoom}
                bookings={props.day12Bookings}
                day={day12}
                onBookingIntent={bookingIntent}
              />
            </SwiperSlide>
            <SwiperSlide>
              <div className="absolute top-0 left-0 right-0 text-center">
                {displayDate(day13)}
              </div>
              <BookingWidgetColumn
                currentRoom={props.currentRoom}
                bookings={props.day13Bookings}
                day={day13}
                onBookingIntent={bookingIntent}
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      {isBooking ? (
        <Typebox
          onCancel={cancelBox}
          onBooking={book}
          onDelete={deleteBooking}
          alreadyName={alreadyName}
          timeOfBooking={timeOfBooking}
          dayOfBooking={dayOfBooking}
        />
      ) : null}
    </Fragment>
  )
}

export default BookingWidget
