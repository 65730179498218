import { initializeApp } from 'firebase/app';
import { getDatabase, onValue, ref, update } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyB6PvByCJ0ZF_z_vCNdfrnfQjFuI_xMr40",
  authDomain: "kelp-rooms.firebaseapp.com",
  databaseURL: "https://kelp-rooms-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "kelp-rooms",
  storageBucket: "kelp-rooms.appspot.com",
  messagingSenderId: "388877726825",
  appId: "1:388877726825:web:cf67ba2a651528dcde5ef8",
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase();

export { firebaseApp, db, ref, update, onValue };