import { Fragment, useEffect, useState } from 'react'
import BookingWidget from './components/booking-widget/BookingWidget'
import { ref, db, onValue, update } from './utils/firebase'
import { LuHeadphones, LuDoorOpen, LuUsers2 } from 'react-icons/lu'

import logo from './img/logo.png'

function App() {
  const [isUserIdle, setIsUserIdle] = useState(0)
  const [day0Bookings, setDay0Bookings] = useState(0)
  const [day1Bookings, setDay1Bookings] = useState(0)
  const [day2Bookings, setDay2Bookings] = useState(0)
  const [day3Bookings, setDay3Bookings] = useState(0)
  const [day4Bookings, setDay4Bookings] = useState(0)
  const [day5Bookings, setDay5Bookings] = useState(0)
  const [day6Bookings, setDay6Bookings] = useState(0)
  const [day7Bookings, setDay7Bookings] = useState(0)
  const [day8Bookings, setDay8Bookings] = useState(0)
  const [day9Bookings, setDay9Bookings] = useState(0)
  const [day10Bookings, setDay10Bookings] = useState(0)
  const [day11Bookings, setDay11Bookings] = useState(0)
  const [day12Bookings, setDay12Bookings] = useState(0)
  const [day13Bookings, setDay13Bookings] = useState(0)

  const [currentRoom, setCurrentRoom] = useState('bb')

  const userIsInactive = () => {
    setIsUserIdle(1)
  }

  const inactivityTime = () => {
    setTimeout(userIsInactive, 60000)
  }
  inactivityTime()

  const setRoom = (room) => {
    setCurrentRoom(room)
  }

  useEffect(() => {
    const d = new Date()
    const day0 =
      d.getUTCFullYear() + '-' + (d.getUTCMonth() + 1) + '-' + d.getUTCDate()
    const day0Bookings = ref(db, 'bookings/' + day0)
    onValue(day0Bookings, (snapshot) => {
      setDay0Bookings(snapshot.val())
    })

    const d1 = new Date()
    d1.setDate(d1.getUTCDate() + 1)
    const day1 =
      d1.getUTCFullYear() + '-' + (d1.getUTCMonth() + 1) + '-' + d1.getUTCDate()
    const day1Bookings = ref(db, 'bookings/' + day1)
    onValue(day1Bookings, (snapshot) => {
      setDay1Bookings(snapshot.val())
    })

    const d2 = new Date()
    d2.setDate(d2.getUTCDate() + 2)
    const day2 =
      d2.getUTCFullYear() + '-' + (d2.getUTCMonth() + 1) + '-' + d2.getUTCDate()
    const day2Bookings = ref(db, 'bookings/' + day2)
    onValue(day2Bookings, (snapshot) => {
      setDay2Bookings(snapshot.val())
    })

    const d3 = new Date()
    d3.setDate(d3.getUTCDate() + 3)
    const day3 =
      d3.getUTCFullYear() + '-' + (d3.getUTCMonth() + 1) + '-' + d3.getUTCDate()
    const day3Bookings = ref(db, 'bookings/' + day3)
    onValue(day3Bookings, (snapshot) => {
      setDay3Bookings(snapshot.val())
    })

    const d4 = new Date()
    d4.setDate(d4.getUTCDate() + 4)
    const day4 =
      d4.getUTCFullYear() + '-' + (d4.getUTCMonth() + 1) + '-' + d4.getUTCDate()
    const day4Bookings = ref(db, 'bookings/' + day4)
    onValue(day4Bookings, (snapshot) => {
      setDay4Bookings(snapshot.val())
    })

    const d5 = new Date()
    d5.setDate(d5.getUTCDate() + 5)
    const day5 =
      d5.getUTCFullYear() + '-' + (d5.getUTCMonth() + 1) + '-' + d5.getUTCDate()
    const day5Bookings = ref(db, 'bookings/' + day5)
    onValue(day5Bookings, (snapshot) => {
      setDay5Bookings(snapshot.val())
    })

    const d6 = new Date()
    d6.setDate(d6.getUTCDate() + 6)
    const day6 =
      d6.getUTCFullYear() + '-' + (d6.getUTCMonth() + 1) + '-' + d6.getUTCDate()
    const day6Bookings = ref(db, 'bookings/' + day6)
    onValue(day6Bookings, (snapshot) => {
      setDay6Bookings(snapshot.val())
    })

    const d7 = new Date()
    d7.setDate(d7.getUTCDate() + 7)
    const day7 =
      d7.getUTCFullYear() + '-' + (d7.getUTCMonth() + 1) + '-' + d7.getUTCDate()
    const day7Bookings = ref(db, 'bookings/' + day7)
    onValue(day7Bookings, (snapshot) => {
      setDay7Bookings(snapshot.val())
    })

    const d8 = new Date()
    d8.setDate(d8.getUTCDate() + 8)
    const day8 =
      d8.getUTCFullYear() + '-' + (d8.getUTCMonth() + 1) + '-' + d8.getUTCDate()
    const day8Bookings = ref(db, 'bookings/' + day8)
    onValue(day8Bookings, (snapshot) => {
      setDay8Bookings(snapshot.val())
    })

    const d9 = new Date()
    d9.setDate(d9.getUTCDate() + 9)
    const day9 =
      d9.getUTCFullYear() + '-' + (d9.getUTCMonth() + 1) + '-' + d9.getUTCDate()
    const day9Bookings = ref(db, 'bookings/' + day9)
    onValue(day9Bookings, (snapshot) => {
      setDay9Bookings(snapshot.val())
    })

    const d10 = new Date()
    d10.setDate(d10.getUTCDate() + 10)
    const day10 =
      d10.getUTCFullYear() +
      '-' +
      (d10.getUTCMonth() + 1) +
      '-' +
      d10.getUTCDate()
    const day10Bookings = ref(db, 'bookings/' + day10)
    onValue(day10Bookings, (snapshot) => {
      setDay10Bookings(snapshot.val())
    })

    const d11 = new Date()
    d11.setDate(d11.getUTCDate() + 11)
    const day11 =
      d11.getUTCFullYear() +
      '-' +
      (d11.getUTCMonth() + 1) +
      '-' +
      d11.getUTCDate()
    const day11Bookings = ref(db, 'bookings/' + day11)
    onValue(day11Bookings, (snapshot) => {
      setDay11Bookings(snapshot.val())
    })

    const d12 = new Date()
    d12.setDate(d12.getUTCDate() + 12)
    const day12 =
      d12.getUTCFullYear() +
      '-' +
      (d12.getUTCMonth() + 1) +
      '-' +
      d12.getUTCDate()
    const day12Bookings = ref(db, 'bookings/' + day12)
    onValue(day12Bookings, (snapshot) => {
      setDay12Bookings(snapshot.val())
    })

    const d13 = new Date()
    d13.setDate(d13.getUTCDate() + 13)
    const day13 =
      d13.getUTCFullYear() +
      '-' +
      (d13.getUTCMonth() + 1) +
      '-' +
      d13.getUTCDate()
    const day13Bookings = ref(db, 'bookings/' + day13)
    onValue(day13Bookings, (snapshot) => {
      setDay13Bookings(snapshot.val())
    })
  }, [])

  const setBooking = (day, time, name) => {
    update(ref(db, 'bookings/' + day + '/' + currentRoom), { [time]: name })
  }

  return (
    <Fragment>
      {isUserIdle === 1 && (
        <div className="idleOverlay">
          <h3>Please refresh the page to avoid booking conflicts.</h3>
          <button
            onClick={() => {
              window.location.reload()
            }}
          >
            Refresh
          </button>
        </div>
      )}

      <div className="HeaderBox sm:px-10">
        <div className="Header">
          <img className="Logo" src={logo} alt="Kelp logo" />
          <button
            className={`text-base px-3 py-3 bg-white opacity-50 text-grey shadow-lg ${
              currentRoom === 'bb' ? 'button-active' : ''
            }`}
            onClick={() => setRoom('bb')}
          >
            <LuDoorOpen class="relative -top-0.5 inline" /> Back
          </button>
          &nbsp;
          <button
            className={`text-base px-3 py-3 bg-white opacity-50 text-grey rounded-tr-lg shadow-lg ${
              currentRoom === 'fb' ? 'button-active' : ''
            }`}
            onClick={() => setRoom('fb')}
          >
            <LuDoorOpen class="relative -top-0.5 inline" /> Front
          </button>
          &nbsp;
          <button
            className={`text-base px-3 py-3 bg-white opacity-50 text-grey rounded-tr-lg shadow-lg ${
              currentRoom === 'sb1' ? 'button-active' : ''
            }`}
            onClick={() => setRoom('sb1')}
          >
            <LuHeadphones class="inline relative -top-0.5" /> Small 1
          </button>
          &nbsp;
          <button
            className={`text-base px-3 py-3 bg-white opacity-50 text-grey rounded-tr-lg shadow-lg ${
              currentRoom === 'sb2' ? 'button-active' : ''
            }`}
            onClick={() => setRoom('sb2')}
          >
            <LuHeadphones class="inline relative -top-0.5" /> Small 2
          </button>
          &nbsp;
          <button
            className={`text-base px-3 py-3 bg-white opacity-50 text-grey rounded-tr-lg shadow-lg ${
              currentRoom === 'sb3' ? 'button-active' : ''
            }`}
            onClick={() => setRoom('sb3')}
          >
            <LuHeadphones class="inline relative -top-0.5" /> Small 3
          </button>
        </div>
      </div>

      <BookingWidget
        currentRoom={currentRoom}
        day0Bookings={day0Bookings}
        day1Bookings={day1Bookings}
        day2Bookings={day2Bookings}
        day3Bookings={day3Bookings}
        day4Bookings={day4Bookings}
        day5Bookings={day5Bookings}
        day6Bookings={day6Bookings}
        day7Bookings={day7Bookings}
        day8Bookings={day8Bookings}
        day9Bookings={day9Bookings}
        day10Bookings={day10Bookings}
        day11Bookings={day11Bookings}
        day12Bookings={day12Bookings}
        day13Bookings={day13Bookings}
        onBooking={setBooking}
      />

      <div className="Footer">Made with 💛</div>
    </Fragment>
  )
}

export default App
