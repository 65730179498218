function BookingWidgetColumn(props) {
  const b0700 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['0700']
      ? props.bookings[props.currentRoom]['0700']
      : ''
  const b0730 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['0730']
      ? props.bookings[props.currentRoom]['0730']
      : ''
  const b0800 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['0800']
      ? props.bookings[props.currentRoom]['0800']
      : ''
  const b0830 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['0830']
      ? props.bookings[props.currentRoom]['0830']
      : ''
  const b0900 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['0900']
      ? props.bookings[props.currentRoom]['0900']
      : ''
  const b0930 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['0930']
      ? props.bookings[props.currentRoom]['0930']
      : ''
  const b1000 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['1000']
      ? props.bookings[props.currentRoom]['1000']
      : ''
  const b1030 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['1030']
      ? props.bookings[props.currentRoom]['1030']
      : ''
  const b1100 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['1100']
      ? props.bookings[props.currentRoom]['1100']
      : ''
  const b1130 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['1130']
      ? props.bookings[props.currentRoom]['1130']
      : ''
  const b1200 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['1200']
      ? props.bookings[props.currentRoom]['1200']
      : ''
  const b1230 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['1230']
      ? props.bookings[props.currentRoom]['1230']
      : ''
  const b1300 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['1300']
      ? props.bookings[props.currentRoom]['1300']
      : ''
  const b1330 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['1330']
      ? props.bookings[props.currentRoom]['1330']
      : ''
  const b1400 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['1400']
      ? props.bookings[props.currentRoom]['1400']
      : ''
  const b1430 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['1430']
      ? props.bookings[props.currentRoom]['1430']
      : ''
  const b1500 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['1500']
      ? props.bookings[props.currentRoom]['1500']
      : ''
  const b1530 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['1530']
      ? props.bookings[props.currentRoom]['1530']
      : ''
  const b1600 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['1600']
      ? props.bookings[props.currentRoom]['1600']
      : ''
  const b1630 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['1630']
      ? props.bookings[props.currentRoom]['1630']
      : ''
  const b1700 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['1700']
      ? props.bookings[props.currentRoom]['1700']
      : ''
  const b1730 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['1730']
      ? props.bookings[props.currentRoom]['1730']
      : ''
  const b1800 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['1800']
      ? props.bookings[props.currentRoom]['1800']
      : ''
  const b1830 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['1830']
      ? props.bookings[props.currentRoom]['1830']
      : ''
  const b1900 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['1900']
      ? props.bookings[props.currentRoom]['1900']
      : ''
  const b1930 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['1930']
      ? props.bookings[props.currentRoom]['1930']
      : ''
  const b2000 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['2000']
      ? props.bookings[props.currentRoom]['2000']
      : ''
  const b2030 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['2030']
      ? props.bookings[props.currentRoom]['2030']
      : ''
  const b2100 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['2100']
      ? props.bookings[props.currentRoom]['2100']
      : ''
  const b2130 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['2130']
      ? props.bookings[props.currentRoom]['2130']
      : ''
  const b2200 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['2200']
      ? props.bookings[props.currentRoom]['2200']
      : ''
  const b2230 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['2230']
      ? props.bookings[props.currentRoom]['2230']
      : ''
  const b2300 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['2300']
      ? props.bookings[props.currentRoom]['2300']
      : ''
  const b2330 =
    props.bookings &&
    props.bookings[props.currentRoom] &&
    props.bookings[props.currentRoom]['2330']
      ? props.bookings[props.currentRoom]['2330']
      : ''

  return (
    <div className="Column mt-8 mb-8">
      <div
        onClick={() => props.onBookingIntent(props.day, '0700', b0700)}
        className="Cell p-1"
      >
        {b0700}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '0730', b0730)}
        className="Cell half p-1"
      >
        {b0730}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '0800', b0800)}
        className="Cell p-1"
      >
        {b0800}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '0830', b0830)}
        className="Cell half p-1"
      >
        {b0830}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '0900', b0900)}
        className="Cell p-1"
      >
        {b0900}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '0930', b0930)}
        className="Cell half p-1"
      >
        {b0930}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '1000', b1000)}
        className="Cell p-1"
      >
        {b1000}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '1030', b1030)}
        className="Cell half p-1"
      >
        {b1030}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '1100', b1100)}
        className="Cell p-1"
      >
        {b1100}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '1130', b1130)}
        className="Cell half p-1"
      >
        {b1130}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '1200', b1200)}
        className="Cell p-1"
      >
        {b1200}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '1230', b1230)}
        className="Cell half p-1"
      >
        {b1230}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '1300', b1300)}
        className="Cell p-1"
      >
        {b1300}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '1330', b1330)}
        className="Cell half p-1"
      >
        {b1330}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '1400', b1400)}
        className="Cell p-1"
      >
        {b1400}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '1430', b1430)}
        className="Cell half p-1"
      >
        {b1430}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '1500', b1500)}
        className="Cell p-1"
      >
        {b1500}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '1530', b1530)}
        className="Cell half p-1"
      >
        {b1530}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '1600', b1600)}
        className="Cell p-1"
      >
        {b1600}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '1630', b1630)}
        className="Cell half p-1"
      >
        {b1630}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '1700', b1700)}
        className="Cell p-1"
      >
        {b1700}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '1730', b1730)}
        className="Cell half p-1"
      >
        {b1730}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '1800', b1800)}
        className="Cell p-1"
      >
        {b1800}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '1830', b1830)}
        className="Cell half p-1"
      >
        {b1830}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '1900', b1900)}
        className="Cell p-1"
      >
        {b1900}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '1930', b1930)}
        className="Cell half p-1"
      >
        {b1930}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '2000', b2000)}
        className="Cell p-1"
      >
        {b2000}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '2030', b2030)}
        className="Cell half p-1"
      >
        {b2030}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '2100', b2100)}
        className="Cell p-1"
      >
        {b2100}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '2130', b2130)}
        className="Cell half p-1"
      >
        {b2130}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '2200', b2200)}
        className="Cell p-1"
      >
        {b2200}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '2230', b2230)}
        className="Cell half p-1"
      >
        {b2230}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '2300', b2300)}
        className="Cell p-1"
      >
        {b2300}
      </div>
      <div
        onClick={() => props.onBookingIntent(props.day, '2330', b2330)}
        className="Cell half p-1"
      >
        {b2330}
      </div>
    </div>
  )
}

export default BookingWidgetColumn
